'use client'

import cn from 'classnames'
import { Icon } from '@/app/src/shared/ui/Icon'
import { AnyIconName } from '@/app/src/shared/ui/Icon/lib'

import './Socials.scss'
import { socials } from '../../config/socials'

export type IProps = {
  className?: string
  variant?: 'primary' | 'secondary' | 'third'
  showIcons?: AnyIconName[]
  sharerOptions?: {
    'data-title': string
    'data-url': string
  } | null
}

const Socials = ({ className, variant = 'secondary', showIcons, sharerOptions }: IProps) => {
  const hasSharer = !!sharerOptions

  const filteredSocials = showIcons?.length
    ? socials.filter((i) => showIcons.includes(i.icon))
    : socials

  const filteredSharer = hasSharer
    ? filteredSocials.filter((item) => item.sharerName)
    : filteredSocials

  const handleClick = (e: React.SyntheticEvent) => {
    if (!hasSharer) return

    e.preventDefault()
  }

  return (
    <ul
      className={cn('socials', `socials--variant-${variant}`, {
        [`${className}`]: !!className,
      })}
    >
      {filteredSharer.map(({ icon, link, sharerName }, idx) => {
        const sharerProps = hasSharer
          ? {
              ...sharerOptions,
              ['data-sharer']: sharerName || undefined,
            }
          : {}

        return (
          <li key={idx} className={cn('socials__item')}>
            <a
              suppressHydrationWarning
              onClick={(e) => handleClick(e)}
              href={link}
              target="_blank"
              className={cn('socials__item-link', `socials__item-link--${icon}`)}
              {...sharerProps}
            >
              <Icon name={`${icon}`} width="24" height="24" className={cn('socials__item-icon')} />
            </a>
          </li>
        )
      })}
    </ul>
  )
}

export default Socials
