export const socials = [
  {
    sharerName: 'facebook',
    icon: 'social/facebook',
    link: 'https://www.facebook.com/DevelopmentFamily',
  },
  {
    sharerName: 'twitter',
    icon: 'social/twitter',
    link: 'https://twitter.com/dev___family',
  },
  {
    sharerName: null,
    icon: 'social/instagram',
    link: 'https://www.instagram.com/dev.family.team',
  },
  {
    sharerName: 'linkedin',
    icon: 'social/linkedin',
    link: 'https://www.linkedin.com/company/dev-family',
  },
  {
    sharerName: null,
    icon: 'social/medium',
    link: 'https://medium.com/@dev.family',
  },
  {
    sharerName: null,
    icon: 'social/github',
    link: 'https://github.com/dev-family',
  },
  {
    sharerName: null,
    icon: 'social/behance',
    link: 'https://www.behance.net/dev-family',
  },
  {
    sharerName: null,
    icon: 'social/dribbble',
    link: 'https://dribbble.com/devfamily',
  },
  {
    sharerName: null,
    icon: 'social/youtube',
    link: 'https://www.youtube.com/@dev.family',
  },
] as const
